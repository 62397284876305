import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { decryptionAPI } from "../../utils/Encryption";
const API_URL = "http://localhost:5099/";
const UPLOAD_ENDPOINT = "api/Common/SaveFileToAmazon";

function TinyEceEditor({ handleChange, ...props }) {
  const custom_config = {
    extraPlugins: [uploadPlugin],
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "insertTable",
        "|",
        // 'imageUpload',
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  };
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("filename", file.filename);

            var config = { headers: { "content-type": "multipart/form-data" } };

            axios
              .post(`${API_URL}/${UPLOAD_ENDPOINT}`, body, config)
              .then((response) => {
                response = decryptionAPI(response.data);
                resolve({
                  default: `${API_URL}/${response.filePath}`,
                });
              })
              .catch((error) => {
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className="tiny-editor-sec" key={props && props.key ? `${props.key}div` : "TinyMCESection"}>
      <Editor
        value={props.data}
        key={props && props.key ? props.key : "TinyMCE"}
        //config={custom_config}
        // onReady={(editor) => {}}
        // onBlur={(event, editor) => {}}
        // onFocus={(event, editor) => {}}
        // onChange={(event, editor) => {
        //   handleChange(editor.getData());
        // }}
        onEditorChange={(newText, editor) => props.onChange(newText, editor)}
        {...props}
        init={{

          plugins: "link image code lists wordcount table paste",
          //toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist checklist outdent indent | wordcount | table ",
          statusbar: false,
          visual: false,
          content_style: '#tinymce{color:#181818;} table tr th{padding:5px;}',
          font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;Galvji Regular;Galvji Bold; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;',
          content_css: "https://web.sgtech-test.infixsofttech.com/admin-fonts/font.css",
          // content_css: "index.css",
          toolbar: false,
          menubar: false,
          paste_as_text: true
        }}
        // apiKey="abgacn6b2jrusncpwzlgg311vrm7p5pey06jwehu1pkfbtej"
          apiKey="huxg5m2se6sei9luvxhgl4nhbi2z6onddbtitgbn9fr2ky39"
      />
    </div>
  );
}

export default TinyEceEditor;
